import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_CheckmarkCircle(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 29.3333C8.63602 29.3333 2.66669 23.364 2.66669 16C2.66669 8.63599 8.63602 2.66666 16 2.66666C23.364 2.66666 29.3334 8.63599 29.3334 16C29.3334 23.364 23.364 29.3333 16 29.3333ZM14.6707 21.3333L24.0974 11.9053L22.212 10.02L14.6707 17.5627L10.8987 13.7907L9.01335 15.676L14.6707 21.3333Z"
        fill={color}
      />
    </svg>
  );
}
