import React from 'react';
import ReactModal from 'react-modal';

const defaultModalProps = {
  isOpen: true,
  className: 'EviteModal__Content-FullPage',
  overlayClassName: 'EviteModal__Overlay',
  closeTimeoutMS: 200,
};

let appElementSet = false;

export function Modal(props) {
  const {onClose, className: a} = props;
  if (!appElementSet) {
    ReactModal.setAppElement('#pbModalRoot');
    appElementSet = true;
  }

  if (!onClose) console.warn('Missing required onClose prop for Modal');

  const className = a || defaultModalProps.className;

  const combinedProps = {
    ...defaultModalProps,
    ...props,
    className,
  };

  return <ReactModal {...combinedProps} />;
}
