export const SET_AVATARS = 'ViewInvitation/avatars/set_avatars';
export const UPDATE_AVATARS = 'ViewInvitation/avatars/update_avatars';

export function selectAvatar(userId) {
  return (state) => state?.avatars?.userAvatars[userId] ?? '';
}

const initialState = {
  userAvatars: {},
  avatarsFetched: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AVATARS:
      let avatars = {...(action.avatars || action.results)};
      delete avatars.data_layer;
      return {
        userAvatars: avatars,
        avatarsFetched: true,
      };
    case UPDATE_AVATARS:
      avatars = {...(action.avatars || action.results)};
      delete avatars.data_layer;
      return {
        userAvatars: {
          ...state.userAvatars,
          ...avatars,
        },
        avatarsFetched: true,
      };
    default:
      return state;
  }
}
