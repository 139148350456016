import {useState, useEffect} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Counter} from '~/common/_pb_components/atoms/Counter';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_AlertLine} from '~/common/svg/PB_AlertLine';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Input} from '~/common/_pb_components/atoms/Input';
import {Checkbox} from '~/common/_pb_components/atoms/Checkbox';
import {Button} from '~/common/_pb_components/atoms/Button';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {isSaveTheDateByUsedAs} from '~/common/utils/event_utils';
import {validateEmail} from '../utils/validateEmail';
import {largishAndUp} from '~sass/pb_styleguide/base/_exports.sass';

export function RSVPModal(props) {
  const history = useHistory();
  const location = useLocation();

  const {matches: largeScreens} = useMatchQuery(`(min-width: ${largishAndUp})`);
  const {
    notifications: notificationOptions,
    isSMSInvite,
    guestEmail,
    showNotifyMarketing,
    name,
    rsvpdAdults,
    rsvpdKids,
    rsvpdOptin,
    rsvpdOutout,
    hasRsvpd,
  } = useSelector(
    (state) => ({
      ...state.rsvp,
      isSMSInvite: state.rsvp?.inviteMethod === 'sms',
      guestEmail: state.rsvp?.guestEmail ?? '',
      showNotifyMarketing: state.rsvp?.showNotifyMarketing ?? true,
      name: state.rsvp?.name ?? state.user?.name ?? '',
      rsvpdAdults: state.rsvp?.totalAdults ?? 0,
      rsvpdKids: state.rsvp?.totalKids ?? 0,
      rsvpdOptin: state.rsvp?.optin ?? false,
      rsvpdOutout: state.rsvp?.optout ?? false,
      hasRsvpd: state.rsvp?.hasRsvpd ?? false,
    }),
    shallowEqual
  );
  const guest_id = useSelector((state) => state.user?.guestId ?? '');
  const {allowKids, maxAdditionalGuests, allowGuestsToBringMoreGuests, allowGuestsToInviteMore} =
    useSelector(
      (state) => ({
        allowKids: !!state.eventDetails.allowKids,
        maxAdditionalGuests: state.eventDetails.maxGuests,
        allowGuestsToBringMoreGuests: state.eventDetails?.allowGuests ?? false,
        allowGuestsToInviteMore: state.eventDetails?.allowInviteMore ?? false,
      }),
      shallowEqual
    );
  const allowGuestsToInvite = useSelector(
    (state) => !!allowGuestsToInviteMore && !!maxAdditionalGuests && !state.rsvp.guestOf
  );

  const isPrivateGuestlist = useSelector(
    (state) => state?.whoIsComing?.isPrivateGuestlist ?? false
  );
  const usedAs = useSelector((state) => state.eventDetails?.usedAs);
  const isSaveTheDate = isSaveTheDateByUsedAs(usedAs);

  const notificationsSubscribed =
    !!notificationOptions.push_new_comments ||
    !!notificationOptions.push_new_rsvp ||
    !!notificationOptions.email_new_comments ||
    !!notificationOptions.notify_only_my_activity;

  const [replyAsName, setReplyAsName] = useState('');
  const [replyAsEmail, setReplyAsEmail] = useState();
  const [rsvpComment, setRsvpComment] = useState('');
  const [eventNotifications, setEventNotifications] = useState(notificationsSubscribed);
  const [marketingNotifications, setMarketingNotifications] = useState(true);
  const [notifyGuestRSVPs, setNotifyGuestRSVPs] = useState(notificationOptions.email_new_rsvp);
  const [editOn, setEditOn] = useState(false);
  const [notYou, setNotYou] = useState(false);
  const [forceRSVPForm, setForceRSVPForm] = useState(false);

  const [numAdults, setNumAdults] = useState(1);
  const [numKids, setNumKids] = useState(0);

  const [rsvpDone, setRsvpDone] = useState(false);

  useEffect(() => {
    if (rsvpDone) {
      history.replace(`/frp/rsvp?gid=${window.guest_id}`, location.state);
    }
  }, [rsvpDone]);

  useEffect(() => {
    const notificationsSubscribed =
      !!notificationOptions.push_new_comments ||
      !!notificationOptions.push_new_rsvp ||
      !!notificationOptions.email_new_comments ||
      !!notificationOptions.notify_only_my_activity;

    setReplyAsName(name);
    setReplyAsEmail(guestEmail);
    if (!guestEmail || !name) setForceRSVPForm(true);

    setNumAdults(rsvpdAdults);
    setNumKids(rsvpdKids);
    setEventNotifications(!hasRsvpd ? true : notificationsSubscribed);
    setMarketingNotifications(rsvpdOptin || !rsvpdOutout);
    setNotifyGuestRSVPs(notificationOptions.email_new_rsvp);
  }, [name, guestEmail, rsvpdAdults, rsvpdKids, rsvpdOptin, rsvpdOutout, notificationOptions]);

  const onNotYouPress = () => {
    setReplyAsName('');
    setReplyAsEmail('');
    setNotYou(true);
  };

  const onChangeReplyAsCancel = () => {
    setEditOn(false);
    setNotYou(false);
    setReplyAsName(name);
    setReplyAsEmail(guestEmail);
  };

  const onEditPress = () => {
    setEditOn(true);
  };

  const pushRsvpAnalytics = (rsvpResponse) => {
    if (rsvpResponse === 'yes') {
      window.dataLayer.push({event: 'rsvp_yes_complete'});
    } else if (rsvpResponse === 'no') {
      window.dataLayer.push({event: 'rsvp_no_complete'});
    }
  };

  const onSubmit = () =>
    new Promise((resolve, reject) => {
      let email = replyAsEmail?.trim() || guestEmail;
      if (!props.status) {
        // Somehow the modal has been opened without an RSVP status; do not submit RSVP
        toastAlert('No RSVP status chosen', {
          type: 'error',
          icon: <PB_AlertLine size={0.75} />,
        });
        reject('No RSVP status chosen');
        return;
      }
      if (notYou || forceRSVPForm) {
        const res = validateEmail(replyAsEmail);
        if (!res.valid) {
          toastAlert(res.message, {
            type: 'error',
            icon: <PB_AlertLine size={0.75} />,
          });
          reject(res.message);
          return;
        }
        email = res.value;
      } else if (numAdults + numKids > maxAdditionalGuests) {
        toastAlert(
          `The host has set the limit to ${maxAdditionalGuests} guests (including yourself)`,
          {
            type: 'error',
            icon: <PB_AlertLine size={0.75} />,
          }
        );
        reject('No RSVP status chosen');
        return;
      }
      let payload = {
        name: replyAsName.trim(),
        email,
        response: props.status.toLowerCase(),
        adults_in_party: numAdults,
        kids_in_party: numKids,
        comments: rsvpComment,
        optin: marketingNotifications,
      };
      // Update notification
      if (eventNotifications !== notificationsSubscribed) {
        payload = {
          ...payload,
          notification_options: {
            email_new_comments: eventNotifications,
            email_new_rsvp: !!notifyGuestRSVPs,
            push_new_comments: eventNotifications,
            push_new_rsvp: !hasRsvpd
              ? eventNotifications
              : eventNotifications && notificationOptions.push_new_rsvp,
            notify_only_my_activity: eventNotifications,
            notify_only_my_activity_push: eventNotifications,
            notify_game: {},
          },
        };
      } else {
        payload = {
          ...payload,
          notification_options: {
            email_new_comments: !!notificationOptions.email_new_comments,
            email_new_rsvp: !!notifyGuestRSVPs,
            push_new_comments: !!notificationOptions.push_new_comments,
            push_new_rsvp: !!notificationOptions.push_new_rsvp,
            notify_only_my_activity: !!notificationOptions.notify_only_my_activity,
            notify_only_my_activity_push: !!notificationOptions.notify_only_my_activity_push,
            notify_game: {},
          },
        };
      }
      if (guest_id && (!replyAsEmail || guestEmail === replyAsEmail?.trim() || isSMSInvite)) {
        payload = {...payload, guest_id};
      }
      props
        .onSubmitRSVP(guest_id, payload)
        .then(() => {
          setEditOn(false);
          setNotYou(false);
          setRsvpDone(true);
          resolve();
          pushRsvpAnalytics(payload.response);
        })
        .catch(reject);
    });

  return (
    <div className={`view-invitation__rsvp-modal ${props.open ? 'open' : ''}`}>
      {largeScreens && (
        <>
          <div className="rsvp-modal__large-title">
            <Typography variant="label1">RSVP</Typography>
            <button
              className="unstyled-button"
              onClick={props.onClose}
              data-qa-id="rsvp-modal-close"
            >
              <PB_CloseLine ratio={1} />
            </button>
          </div>
          <Divider marginTop="1.5rem" marginBottom="1.5rem" />
        </>
      )}
      <div className={`rsvp-modal__heading ${props.status || ''}`}>
        <Typography
          variant="header3"
          data-qa-id="rsvp-modal-header"
          classNames={`rsvp-modal__header-text ${props.status?.toLowerCase() || ''}`}
        >
          {
            props.status === 'Yes'
              ? 'You will be attending!'
              : props.status === 'No'
                ? 'You are not attending'
                : 'You are RSVPing Maybe' // TODO: Theme 'Maybe' according to chosen theme
          }
        </Typography>
        {!largeScreens && (
          <button className="unstyled-button" onClick={props.onClose} data-qa-id="rsvp-modal-close">
            <PB_CloseLine ratio={0.75} />
          </button>
        )}
      </div>
      <div className="rsvp-modal__body">
        <div
          className={`rsvp-modal__reply-as-wrapper ${
            editOn || notYou || forceRSVPForm ? 'edit-on' : ''
          }`}
        >
          {editOn || notYou || forceRSVPForm ? (
            <>
              <Input
                data-qa-id="replyas-name"
                value={replyAsName}
                onChange={(e) => setReplyAsName(e.target.value)}
                placeholder="RSVP name"
              />
              {(notYou || forceRSVPForm) && (
                <Input
                  data-qa-id="replyas-email"
                  value={replyAsEmail}
                  onChange={(e) => setReplyAsEmail(e.target.value)}
                  placeholder="Email address"
                />
              )}
            </>
          ) : (
            <p className={`rsvp-modal__reply-as-text ${largeScreens ? 'large-screens' : ''}`}>
              Replying as:{' '}
              <span className="rsvp-modal__reply-as-name" data-qa-id="replyas-saved-name">
                {replyAsName}
              </span>
            </p>
          )}
          {!forceRSVPForm && (
            <div className="rsvp-modal__reply-as-ctas">
              {editOn || notYou ? (
                <button
                  className="unstyled-button link small"
                  data-qa-id="cancel-edit-replyas"
                  onClick={onChangeReplyAsCancel}
                >
                  Cancel
                </button>
              ) : (
                <>
                  <button
                    className="unstyled-button link small"
                    data-qa-id="rsvp-edit-replyas"
                    onClick={onEditPress}
                  >
                    Edit
                  </button>
                  {allowGuestsToInvite && (
                    <>
                      <span>|</span>
                      <button
                        className="unstyled-button link small"
                        data-qa-id="rsvp-not-you"
                        onClick={onNotYouPress}
                      >
                        Not you?
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {!largeScreens && <Divider />}
        <div className="rsvp-modal__rsvp-details-wrapper">
          {props.status !== 'No' && allowGuestsToBringMoreGuests && (
            <div className="rsvp-modal__rsvp-counters">
              <Counter
                title={allowKids ? 'Adults' : 'Guests'}
                count={numAdults}
                onPlus={() => setNumAdults(numAdults + 1)}
                onMinus={() => {
                  if (numAdults > 0) setNumAdults(numAdults - 1);
                }}
                data-qa-id="adults-count"
              />
              {allowKids && (
                <Counter
                  title="Kids"
                  count={numKids}
                  onPlus={() => setNumKids(numKids + 1)}
                  onMinus={() => {
                    if (numKids > 0) setNumKids(numKids - 1);
                  }}
                  data-qa-id="kids-count"
                />
              )}
            </div>
          )}
          <Input
            data-qa-id="rsvp-comment"
            value={rsvpComment}
            onChange={(e) => setRsvpComment(e.target.value)}
            placeholder="Add a comment..."
          />
        </div>
        <Divider />
        <div className="rsvp-modal__notification-manager">
          <Typography className="rsvp-modal__notification-manager_sub_copy" variant="paragraph2">
            You will receive event reminders, cancellation notices and messages from the host(s).
            Select additional notifications below:
          </Typography>
          <Checkbox
            onChange={() => setEventNotifications(!eventNotifications)}
            data-qa-id="receive-event-notifications"
            checked={eventNotifications}
            label="All event activity"
          />
          {showNotifyMarketing && (
            <Checkbox
              onChange={() => setMarketingNotifications(!marketingNotifications)}
              data-qa-id="receive-marketing-notifications"
              checked={marketingNotifications}
              label="Party planning tips, gift ideas, special partner offers and more"
            />
          )}
          {!isPrivateGuestlist && !isSaveTheDate && (
            <Checkbox
              onChange={() => setNotifyGuestRSVPs(!notifyGuestRSVPs)}
              data-qa-id="notify-guest-rsvps"
              checked={notifyGuestRSVPs}
              label="RSVPs from other guests"
            />
          )}
        </div>
        <Button
          data-qa-id="submit-rsvp"
          variant="primary"
          size="large"
          processingChildren="Submitting..."
          onClick={onSubmit}
          data-impression-tracking="true"
        >
          Submit RSVP
        </Button>
      </div>
    </div>
  );
}

RSVPModal.propTypes = {
  status: PropTypes.oneOf(['Yes', 'Maybe', 'No']),
  onClose: PropTypes.func,
  onSubmitRSVP: PropTypes.func,
  open: PropTypes.bool,
};
