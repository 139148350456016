import {CaptchaDialog} from '~/modal/index';
import {modal_ajax_error_handler} from '~/modal/_ajax_error_handler';
import {isCanceled} from '~/common/utils/event_utils';

export function reduceReducers(...reducers) {
  // This takes 2 reducers and reduces them into a single reducer (from r to l).
  // We are using this to merge photos and posts reducers, for example. Another
  // way to look at it is extending a reducer. We can extend the post reducer with
  // additional actions from the photos reducer.
  // Example: reducer_A = reduceReducers(reducer_A, reducer_B);
  return (previous, current) => reducers.reduce((p, r) => r(p, current), previous);
}

let guidIndex = 0;
const guidPrefix = Math.floor(Math.random() * 2147483647).toString(16);
export function guid() {
  return `${guidPrefix}-${guidIndex++}`;
}

export function eventHasPassed() {
  return !!window.event_has_passed;
}

export function isPremium() {
  return !!window.is_premium;
}

export function hasAds() {
  return !isPremium() && window.evite_event.remove_ads !== true;
}

export function isCard() {
  return !!window.is_card;
}

export function canSeeGuests() {
  return !window.evite_event.options.event_option_private_guestlist;
}

export function canRSVP() {
  return !isCard() && !eventHasPassed() && !isCanceled(window.evite_event.status);
}

export function isPreview() {
  return !!window.is_preview;
}

export function isMobile() {
  return !!window.is_mobile_site;
}

export function isMobileDevice() {
  return !!window.is_mobile;
}

export function hasWidget(widget_type) {
  if (!window.evite_event) return false;
  const widgets = window.evite_event.widgets || [];
  for (const widget of widgets) {
    if (widget.widget_type === widget_type) return true;
  }
  return false;
}

export function hasSponsoredRegistry() {
  return hasWidget('sponsored_registry');
}

export function getWidget(widget_type) {
  if (!window.evite_event) return false;
  const widgets = window.evite_event.widgets || [];
  for (const widget of widgets) {
    if (widget.widget_type === widget_type) return widget;
  }
}

const {navigator} = window;
const {userAgent} = navigator;

export function isIpad() {
  return Boolean(userAgent.match(/iPad/i));
}

export function isIphone() {
  return Boolean(userAgent.match(/iPhone/i));
}

export function isFireFox() {
  return Boolean(userAgent.match(/firefox/i));
}

export function isAndroid() {
  return Boolean(userAgent.match(/android/i));
}

export function isWindows() {
  return (window.navigator.platform || '').match(/Win/i);
}

export function isHost() {
  return !!window.evite_event.is_host_viewing;
}

export function isUserAHost(userId) {
  return window.evite_event.host_ids.includes(userId);
}

export function isGuest(state = null) {
  if (state) return Boolean(state.rsvp.guestId);
  return Boolean(window.guest_id);
}

export function showKnot(storeName = 'template') {
  return false;
}
export function showBump(storeName = 'template') {
  return false;
}

export function getGiftingType(storeName) {
  const show_knot = window.KNOT_EVENT_TYPES ? showKnot(storeName) : window.show_knot;
  const show_bump = window.BUMP_EVENT_TYPES ? showBump(storeName) : window.show_bump;
  const giftType = show_knot ? 'knot' : show_bump ? 'bump' : 'amazon';

  return giftType;
}

export function hasRsvpd(state = null) {
  if (state) return state.rsvp.hasRsvpd;
  return !!window.has_rsvpd;
}

export function hasAmazonStoreFront() {
  return typeof window.amazon_store_front !== 'undefined' && window.amazon_store_front !== null;
}

export function isVideo() {
  return !!window.evite_event.options.video;
}

export function canComment(state = null) {
  if (isCanceled(window.evite_event.status) || (state && isCanceled(state.event?.status))) {
    return false;
  }
  return isGuest(state) && (hasRsvpd(state) || eventHasPassed());
}

export function canPostPhotos(state = null) {
  return isGuest(state) && (hasRsvpd(state) || eventHasPassed());
}

export function isBreakPoint(name) {
  return evite.dom.isBreakPoint(name);
}

export function canDeleteEventItem(itemUserId) {
  return isHost() || window.user_id === itemUserId;
}

export function canEditEventItem(itemUserId) {
  return window.user_id === itemUserId;
}

export function eventTabUrlPath(urlPart, urlHash = '') {
  const qs = window.location.search;
  return `/event/${window.evite_event.id}/${urlPart}${qs}${urlHash}`;
}

export function eventTabUrlPathName(urlPart) {
  return `/event/${window.evite_event.id}/${urlPart}`;
}

export function handleCaptchaJsonResponse(responseJson) {
  return new Promise((resolve) => {
    new CaptchaDialog({
      challenge: responseJson,
      onSubmit: (_response) => {
        const captchaResponse = {..._response};
        captchaResponse.name = undefined;
        resolve(captchaResponse);
      },
    }).show();
  });
}

export function handleCaptcha(xhr, status, data, callback) {
  if (xhr && xhr.status === 412) {
    const response = JSON.parse(xhr.responseText);
    new CaptchaDialog({
      challenge: response,
      onSubmit(captcha) {
        captcha.name = void 0;
        captcha = JSON.stringify(captcha);
        callback(captcha);
      },
    }).show();
  } else {
    renderAjaxErrorModal(xhr, status, data);
    if (callback) {
      callback('');
    }
  }
}

export function renderAjaxErrorModal(xhr, status, data) {
  const hasStatus = xhr && xhr.status;
  const isServerError = xhr && xhr.status >= 500 && xhr.status < 600;

  if (hasStatus && (isMobile() || isServerError)) {
    let message = 'Sorry, an error has occurred';
    let responseTextMessage;

    try {
      responseTextMessage = JSON.parse(xhr.responseText).message;
    } catch (error) {
      responseTextMessage = xhr.responseText;
    }

    if (isServerError) {
      const consoleTitle = typeof data === 'string' ? data : 'Internal Server Error';
      if (responseTextMessage && console && console.error) {
        console.error(`${consoleTitle}:\n\n${responseTextMessage}\n`);
      }
      message = 'Sorry, an internal server error has occurred';
    } else if (responseTextMessage) {
      message = responseTextMessage;
    }

    alert(message);
  } else {
    modal_ajax_error_handler(xhr, status, data);
  }
}

export function pluralize(label, count) {
  label = count === 1 ? label : `${label}s`;
  return `${count} ${label}`;
}

export function fixupAbsoluteURL(url) {
  // Adds https:// to url if needed
  if (url && !url.match(/https?:\/\//i)) {
    if (url[0] !== '/') url = `//${url}`;
    url = `https:${url}`;
  }
  return url;
}

export function formatMoney(amount) {
  return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
}

export function indexes(source, find) {
  const result = [];
  for (let i = 0; i < source.length; ++i) {
    if (source.substring(i, i + find.length) === find) {
      result.push(i);
    }
  }

  return result;
}

// This should only be called from view invitation - returns whether
// the event being viewed should have its polls be "meal options".
// See EVT-15215 for all the deets.
export function isMealOptionsEvent() {
  return Boolean(window.is_poll_meal_options);
}
