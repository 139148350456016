import * as c from '../constants/event-origins';
import {httpsIfyUrl} from '../utils';
import XDate from 'xdate';
import {convertToIso} from '~/create/utils/formatters';

// This file intended to hold all event util functions shared across various apps

export const getUsedAsTypeName = (used_as) => {
  // Logic should match the corresponding backend function, _get_used_as_type_name
  if (used_as === 'save_the_date') {
    return 'Save the Date';
  }
  if (used_as === 'card') {
    return 'eCard';
  }
  return 'Invitation';
};

export const isSaveTheDateByUsedAs = (usedAs) => usedAs === 'save_the_date';

export const isInOneStepCreateFlow = window.location.pathname.includes('/invitation-maker');

// ORIGIN-BASED HELPERS
export const isGreetingCard = (origin) => c.CARD_ORIGINS.includes(origin);

export const isLegacyGreetingCard = (origin) => c.LEGACY_GRC_ORIGINS.includes(origin);

export const isFabricGreetingCard = (origin) => c.FABRIC_GRC_ORIGINS.includes(origin);

export const isFabric = (origin) => c.FABRIC_EVENT_ORIGINS.includes(origin);

export const isLegacyPremium = (origin) => c.LEGACY_PREMIUM_ORIGINS.includes(origin);

export const eventAtLeast24HoursInPast = (startTime) =>
  new Date(convertToIso(startTime)).getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;

export const eventMoreThan30DaysAgo = (startTime) =>
  new XDate(convertToIso(startTime)).toString('i').slice(0, 16) <
  new Date(new Date().setDate(new Date().getDate() - 30)).toISOString();

// EVENT STATUS-BASED HELPERS
export const isPast = (status) => status === 'past';

export const isDraft = (status) => status === 'draft';

export const prettyStatus = (status) => {
  if (status === 'cancelled') {
    return 'canceled';
  }
  return status;
};

export const isCanceled = (status) => prettyStatus(status) === 'canceled';

// EVENT FLAG HELPERS
export const isRsvpOff = (rsvpOff) => !rsvpOff;

// EVENT TYPE-BASED HELPERS
// TODO: this doesn't make sense: window.mealOptionsEventTypes?.[0].includes(eventType);
// when eventType is an empty string, it checks if the first string `includes` the empty
// string, which is true. Why are we checking whether the array of characters contains
// the full event type?
export const isMealOptionsEventType = (eventType) => {
  const isMealOptionsOnViewInvitation = window.mealOptionsEventTypes?.includes(eventType);
  const isMealOptionsOnCreateFlow = window.mealOptionsEventTypes?.[0].includes(eventType);
  return !!eventType && (isMealOptionsOnViewInvitation || isMealOptionsOnCreateFlow);
};

// FABRIC ART HELPERS
export const fabricDesignWithTextBorderObjectsRemoved = (design) => {
  if (!design) return design;
  const updated = {...design};
  design.backgroundImage = {...design.backgroundImage};
  if (updated.objects) {
    updated.objects = updated.objects.filter((obj) => {
      obj = {...obj};
      return !(!obj || obj.type === 'textBorder');
    });
  }
  return updated;
};

/**
 * Returns a copy of a single fabric design with corrected settings for cross-origin images
 * @param {Fabric Design Object} design
 * @return {Fabric Design Object}
 */
export const fabricDesignWithCrossOriginUpdates = (design) => {
  if (!design) return design;
  const updated = {...design};
  let d = design.backgroundImage;
  if (d) {
    d = {...d};
    if (d.originalUrl) d.originalUrl = httpsIfyUrl(d.originalUrl);
    if (d.src) d.src = d.src.replace('http:', 'https:');
    d.crossOrigin = 'anonymous';
    updated.backgroundImage = d;
  }

  if (updated.objects) {
    updated.objects = updated.objects.map((obj) => {
      obj = {...obj};
      if (obj && ['image', 'uYOImage'].indexOf(obj.type) > -1) {
        if (obj.originalUrl) obj.originalUrl = httpsIfyUrl(obj.originalUrl);
        if (obj.src) obj.src = httpsIfyUrl(obj.src);
        obj.crossOrigin = 'anonymous';
      }
      return obj;
    });
  }
  return updated;
};

/**
 * Returns a copy of fabric_editable_art with corrected settings for cross-origin images for all categories
 *
 * @param {Fabric Editable Art Object} fabricArt
 * @return {Fabric Editable Art Object}
 */
export const fabricArtWithHttpsForAllImgPaths = (fabricArt) => {
  if (!fabricArt) return null;

  // Port all urls to https and add crossOrigin: anonymous
  const fabricArtByCategoryKeys = fabricArt.editable_art
    ? {...fabricArt.editable_art}
    : {...fabricArt};
  const updatedFabricArt = {...fabricArtByCategoryKeys};

  for (const k of Object.keys(updatedFabricArt)) {
    updatedFabricArt[k] = fabricDesignWithCrossOriginUpdates(updatedFabricArt[k]);
  }

  return updatedFabricArt;
};
