import React from 'react';
import {ABTestContext} from './TestContext';

export function TestVariation(props) {
  const {variation, children} = props;

  const returnChildren = (expVariation) => {
    if (variation === expVariation) {
      return children;
    }
    return null;
  };

  return (
    <ABTestContext.Consumer>
      {(expVariation) => returnChildren(expVariation)}
    </ABTestContext.Consumer>
  );
}
