import {useState, useEffect, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {useLocation} from 'react-router-dom';
import urls from 'urls';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {getUserData} from '~/common/auth/utils';
import {getUsedAsTypeName, isCanceled} from '~/common/utils/event_utils';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_AlertLine} from '~/common/svg/PB_AlertLine';
import {PB_CheckmarkCircle} from '~/common/svg/PB_CheckmarkCircle';
import {PB_CloseCircle} from '~/common/svg/PB_CloseCircle';
import {PB_QuestionCircle} from '~/common/svg/PB_QuestionCircle';
import {GuestRsvpActions} from './GuestRsvpActions';
import {submitRSVP} from '../reducers/apiActions';
import {selectEventHasPassed} from '../reducers/eventDetails';
import {MoreDetailsIndicator} from '../routes/Landing/MoreDetailsIndicator';
import {Swirl} from './Swirl';
import {RSVPModal} from './RSVPModal';
import {RSVPModalVariant} from './RSVPModalVariant';
import {GuestReaction} from '~/GuestReaction/GuestReaction';
import {
  largishAndUp,
  emerald,
  feedbackNo,
  feedbackMaybe,
} from '~sass/pb_styleguide/base/_exports.sass';

export function StickyEditEvent({forceShow}) {
  const location = useLocation();
  const store = useStore();
  const {matches: largeScreens} = useMatchQuery(`(min-width: ${largishAndUp})`);

  const hostIds = window.evite_event.host_ids || [];
  const pendingHostIds = window.evite_event.pending_host_ids || [];
  const isCohost = hostIds.includes(window.user_id) && !pendingHostIds.includes(window.user_id);
  const isHostOrCohost = window.user_id === window.evite_event.host_id || isCohost;

  const rsvpOff = useSelector((state) => state.eventDetails.rsvpOff);
  const {isWebview} = window;

  const params = new URLSearchParams(location.search);
  const gid = params.get('gid');

  const event_id = useSelector((state) => state.eventDetails?.eventId ?? null);
  const userData = getUserData(isWebview);
  const {name, email} = useSelector((state) => state.user);
  const guest_id = userData.guest_id || userData.guestId || gid || window.guest_id;

  const [isGuestRsvpVariant, setIsGuestRsvpVariant] = useState(false);

  if (!userData.userName) userData.userName = name;
  if (!userData.email) userData.email = email;
  const hasGuestReacted = window.evite_event?.rsvp?.comments;

  const [hideGuestReactions, setHideGuestReactions] = useState(false);

  const isPremium = useSelector(
    (state) => state.eventDetails?.isPremium || state.eviteEvent.isPremium
  );
  const origin = useSelector((state) => state.eventDetails?.origin || state.eviteEvent.origin);
  const eventStatus = useSelector((state) => state.eventDetails?.status || state.eviteEvent.status);
  const usedAs = useSelector((state) => state.eventDetails?.usedAs || state.eviteEvent?.used_as);
  const rsvpResponse = useSelector((state) => state.rsvp?.rsvpResponse);
  const hasRsvpd = useSelector((state) => state.rsvp?.hasRsvpd);
  const ctaWordings = getUsedAsTypeName(usedAs).toLowerCase();
  const loadedDefaultRsvp = useRef(false);

  const [updatingRsvp, setUpdatingRsvp] = useState(false);
  const [showSwirl, setShowSwirl] = useState(false);
  const [wipRsvpStatus, setWipRsvpStatus] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const showRsvpOverlayOnLoad = urlParams.get('rsvp') === '1';
  const [rsvpOverlayOpen, setRsvpOverlayOpen] = useState(showRsvpOverlayOnLoad);

  const eventHasPassed = useSelector(selectEventHasPassed);
  const eventIsCanceled = isCanceled(eventStatus);
  const [hideMore, setHideMore] = useState(false);

  const [atTop, setAtTop] = useState(true);
  const toggleShowRSVP = () => {
    setAtTop(window.scrollY === 0);
    setHideMore(window.scrollY > 100);
  };
  const onHideSwirl = () => {
    setShowSwirl(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', toggleShowRSVP);

    if (window.location.href.includes('/messages')) {
      setHideGuestReactions(true);
    }

    return () => {
      document.removeEventListener('scroll', toggleShowRSVP);
    };
  }, []);

  const rsvpDrawer = useRef(null);

  useEffect(() => {
    const footer = document.getElementById('footer');
    if (largeScreens) {
      footer.style.paddingBottom = '5rem';
    } else {
      footer.style.paddingBottom = '11rem';
    }
  }, [largeScreens]);

  useEffect(() => {
    if (!loadedDefaultRsvp.current) {
      const params = new URLSearchParams(location.search);
      const status = params.get('rsvp_response');
      if (status) {
        setWipRsvpStatus(`${status[0].toUpperCase()}${status.substring(1)}`);
        setRsvpOverlayOpen(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (rsvpOverlayOpen) {
      setIsGuestRsvpVariant(
        evite.experiments.inVariation('guest_rsvp_modal_06_24', 'variant', false)
      );
    }
  }, [rsvpOverlayOpen]);

  const responseMap = {
    yes: "Yes, you're going",
    maybe: "You RSVP'd Maybe",
    no: "You RSVP'd No",
  };

  const onSubmitRSVP = (guest_id, payload) =>
    new Promise((resolve, reject) => {
      if (!payload.name) {
        toastAlert('Name is required', {
          type: 'error',
          icon: <PB_AlertLine size={0.75} />,
        });
        reject();
      } else if (!payload.email) {
        toastAlert('Email address is required', {
          type: 'error',
          icon: <PB_AlertLine size={0.75} />,
        });
        reject();
      } else {
        store.dispatch(
          submitRSVP({
            event_id,
            guest_id,
            payload,
            successCallback: () => {
              setRsvpOverlayOpen(false);
              resolve();
            },
            failureCallback: (dispatch, getState, {results}) => {
              const message = results?.message;
              console.error(message);
              if (message) {
                toastAlert(message, {
                  type: 'warning',
                  icon: <PB_AlertLine size={0.75} />,
                });
              }
              reject();
            },
          })
        );
      }
    });

  const showReaction =
    !hideGuestReactions && rsvpOff && isWebview === undefined && !hasGuestReacted && !hasRsvpd;
  const showStickyBar = showReaction || isHostOrCohost || (!isHostOrCohost && !rsvpOff);

  return (
    <>
      <div className={`view-invitation__sticky-wrapper ${!atTop || forceShow ? 'open' : ''}`}>
        {largeScreens && (
          <div className={`more-details--sticky-bar${hideMore ? ' hide' : ''}`}>
            <MoreDetailsIndicator preview={false} />
          </div>
        )}
        {showSwirl && <Swirl text="RSVP" onClick={onHideSwirl} qaId="floating-action-rsvp" />}
        {showStickyBar && !showSwirl && (
          <div
            className={`view-invitation__sticky-ctas
                        ${!atTop || forceShow ? 'open' : ''}
                        ${!rsvpOff && (!hasRsvpd || updatingRsvp) ? 'show-rsvp-ctas' : ''}`}
            id="viewInvitationCTAWrapper"
            ref={rsvpDrawer}
          >
            {!isHostOrCohost && (
              <>
                {showReaction ? (
                  <GuestReaction
                    rsvpDrawer={rsvpDrawer}
                    eventId={event_id}
                    guestId={guest_id}
                    eviteType="event"
                  />
                ) : (
                  <div className="view-invitation__rsvp-wrapper">
                    {eventHasPassed ? (
                      <Typography variant="paragraph3" data-qa-id="event-already-occurred">
                        This event has already occurred.
                      </Typography>
                    ) : eventIsCanceled ? (
                      <Typography variant="paragraph3" data-qa-id="event-canceled">
                        This event has been canceled.
                      </Typography>
                    ) : !showSwirl && (!hasRsvpd || updatingRsvp) ? (
                      <GuestRsvpActions
                        hasRsvpd={hasRsvpd}
                        updatingRsvp={updatingRsvp}
                        setUpdatingRsvp={setUpdatingRsvp}
                        setShowSwirl={setShowSwirl}
                        setWipRsvpStatus={setWipRsvpStatus}
                        setRsvpOverlayOpen={setRsvpOverlayOpen}
                        isPremium={isPremium}
                        origin={origin}
                      />
                    ) : (
                      !showSwirl && (
                        <div
                          className="view-invitation__rsvp-details"
                          style={{
                            marginBottom:
                              !isHostOrCohost && hasRsvpd && !updatingRsvp && !largeScreens
                                ? '1rem'
                                : undefined,
                          }}
                        >
                          <div
                            className="view-invitation__rsvp-status-wrapper"
                            data-qa-value={rsvpResponse}
                          >
                            {rsvpResponse === 'yes' ? (
                              <PB_CheckmarkCircle ratio={0.75} color={emerald} />
                            ) : rsvpResponse === 'no' ? (
                              <PB_CloseCircle ratio={0.75} color={feedbackNo} />
                            ) : (
                              rsvpResponse === 'maybe' && (
                                <PB_QuestionCircle ratio={0.75} color={feedbackMaybe} />
                              )
                            )}
                            <Typography
                              variant="paragraph2"
                              classNames="view-invitation__rsvp-status"
                            >
                              {responseMap[rsvpResponse]}
                            </Typography>
                          </div>
                          <button
                            className="unstyled-button link"
                            data-qa-id="invitation-change-rsvp"
                            onClick={() => setUpdatingRsvp(true)}
                          >
                            Change RSVP
                          </button>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )}
            {isHostOrCohost && !updatingRsvp && (
              <Link
                variant="large"
                as="primary"
                fullWidth
                data-qa-id="invitation-manage"
                href={urls.get('event_dashboard', {event_id})}
                className="view-invitation__manage-invitation-link"
              >
                Manage {ctaWordings}
              </Link>
            )}
          </div>
        )}
      </div>
      {rsvpOverlayOpen && <div className="active-overlay-bg" />}
      {isGuestRsvpVariant ? (
        <RSVPModalVariant
          status={wipRsvpStatus}
          onClose={() => setRsvpOverlayOpen(false)}
          open={rsvpOverlayOpen}
          onSubmitRSVP={onSubmitRSVP}
        />
      ) : (
        <RSVPModal
          status={wipRsvpStatus}
          onClose={() => setRsvpOverlayOpen(false)}
          open={rsvpOverlayOpen}
          onSubmitRSVP={onSubmitRSVP}
        />
      )}
    </>
  );
}
