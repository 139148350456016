import React from 'react';
import {v4} from 'uuid';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {Typography} from './Typography';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';

export const toastAlert = (message, options, inToastButtonConfig = {}) => {
  const {icon, type, title, className, toastCallback = null} = options || {};
  let toastFunc = type ? toast[type] : toast;
  if (!toastFunc) {
    console.log('Error - bad type passed to toastAlert');
    toastFunc = toast;
  }
  const toastId = `toast_${v4()}`;

  // This is to disable the default react-toastify icon
  // and use the icon from props inside the body
  if (options.icon) {
    options.icon = '';
  }

  return toast(
    <Toast
      type={type}
      icon={icon}
      message={message}
      className={className}
      title={title}
      onDismiss={() => {
        if (toastCallback) toastCallback();
        toast.dismiss(toastId);
      }}
      buttonText={inToastButtonConfig?.text}
      onButtonClick={inToastButtonConfig?.onClick}
      id={options.id}
    />,
    {
      ...options,
      toastId,
    }
  );
};

export function Toast(props) {
  return (
    <div className={`toast ${props.type || ''} ${props.className || ''}`} id={props.id}>
      {props.icon}
      {props.title ? (
        <div className="toast__content">
          <Typography variant="paragraph2" className="toast__title">
            {props.title}
          </Typography>
          <Typography variant="paragraph2" className="toast__message">
            {props.message}
          </Typography>
        </div>
      ) : (
        <Typography variant="paragraph2" className="toast__message">
          {props.message}
        </Typography>
      )}
      {props.buttonText && <button onClick={props.onButtonClick}>{props.buttonText}</button>}
      <button className="unstyled-button" onClick={props.onDismiss}>
        <PB_CloseLine ratio={0.75} />
      </button>
    </div>
  );
}

Toast.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(['success', 'information', 'warning', 'error']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onDismiss: PropTypes.func,
};
