import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {ABTest} from '~/common/components/ABTest';
import {ORIGIN_DICT} from '~/common/constants/event-origins';

const YesButton = ({setWipRsvpStatus, setRsvpOverlayOpen}) => (
  <Button
    variant="primary"
    fullWidth
    data-qa-id="response-yes-rsvp"
    size="large"
    className="view-invitation__rsvp-cta"
    onClick={() => {
      setWipRsvpStatus('Yes');
      setRsvpOverlayOpen(true);
    }}
  >
    Yes
  </Button>
);

const NoButton = ({setWipRsvpStatus, setRsvpOverlayOpen, isInVariant = false}) => (
  <Button
    variant={isInVariant ? 'secondary' : 'primary'}
    fullWidth
    data-qa-id="response-no-rsvp"
    size="large"
    className="view-invitation__rsvp-cta"
    onClick={() => {
      setWipRsvpStatus('No');
      setRsvpOverlayOpen(true);
    }}
  >
    No
  </Button>
);

const GuestRsvpOriginalButtons = ({setWipRsvpStatus, setRsvpOverlayOpen, isPremium, origin}) => (
  <>
    <YesButton setWipRsvpStatus={setWipRsvpStatus} setRsvpOverlayOpen={setRsvpOverlayOpen} />
    {!isPremium && origin !== ORIGIN_DICT.FABRIC && (
      <Button
        variant="primary"
        fullWidth
        data-qa-id="response-maybe-rsvp"
        size="large"
        className="view-invitation__rsvp-cta"
        onClick={() => {
          setWipRsvpStatus('Maybe');
          setRsvpOverlayOpen(true);
        }}
      >
        Maybe
      </Button>
    )}
    <NoButton setWipRsvpStatus={setWipRsvpStatus} setRsvpOverlayOpen={setRsvpOverlayOpen} />
  </>
);

export const GuestRsvpActions = ({
  hasRsvpd,
  setUpdatingRsvp,
  setWipRsvpStatus,
  setRsvpOverlayOpen,
  isPremium,
  origin,
}) => {
  const isInVariant = evite.experiments.inVariation('rsvp_bar_2024q2_2', 'variant', false);

  return (
    <div className="view-invitation__rsvp-handler">
      <Typography variant="label2" size="large" classNames="view-invitation__rsvp-heading">
        Will you be attending?
      </Typography>
      <div className={cx('view-invitation__rsvp-cta-group', {variation: isInVariant})}>
        <ABTest
          exp="rsvp_bar_2024q2_2"
          original={
            <GuestRsvpOriginalButtons
              origin={origin}
              setRsvpOverlayOpen={setRsvpOverlayOpen}
              setWipRsvpStatus={setWipRsvpStatus}
              isPremium={isPremium}
            />
          }
          variation="variant"
        >
          <NoButton
            setWipRsvpStatus={setWipRsvpStatus}
            setRsvpOverlayOpen={setRsvpOverlayOpen}
            isInVariant
          />
          {!isPremium && origin !== ORIGIN_DICT.FABRIC && (
            <Button
              variant="primary"
              fullWidth
              data-qa-id="response-maybe-rsvp"
              size="large"
              className="view-invitation__rsvp-cta"
              onClick={() => {
                setWipRsvpStatus('Maybe');
                setRsvpOverlayOpen(true);
              }}
            >
              Maybe
            </Button>
          )}
          <YesButton setWipRsvpStatus={setWipRsvpStatus} setRsvpOverlayOpen={setRsvpOverlayOpen} />
        </ABTest>
      </div>
      {hasRsvpd && (
        <Button
          variant="unstyled-button"
          data-qa-id="cancel-rsvp-change"
          className="unstyled-button link"
          onClick={() => setUpdatingRsvp(false)}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};
