function dispatchActions(dispatch, action, actionPayload) {
  // Dispatches one or more actions based on type of actions parameter (string, function or array)
  if (!action) return;
  if (Array.isArray(action)) {
    for (const item of action) dispatchActions(dispatch, item, actionPayload);
    return;
  }
  if (typeof action === 'string') return dispatch({type: action, ...actionPayload});
  if (typeof action === 'function')
    return dispatch((dispatch, getState) => {
      action(dispatch, getState, actionPayload);
    });
}

function reportDataLayer(dataLayer) {
  // TODO: Not used at the moment - I think we want to control the dataLayer pushes from the front-end, not the
  // TODO: ajax api.  Remove?
  if (!dataLayer) return;
  setTimeout(() => {
    evite
      .when('dfp')
      .then(() => {
        if (window.dataLayer) {
          window.dataLayer.push(dataLayer);
        }
      })
      .catch((e) => {
        evite.error('Failed to update dataLayer');
        Raven.captureException(e);
      });
  }, 100);
}

export function apiFetcher(
  resource, // url or {url, method, payload}
  startAction,
  successAction,
  failureAction,
  actionPayload, // data to include in the start/success/failure actions
  retries = 3
) {
  let method = 'GET';
  let payload;
  let url;
  let contentType = 'application/json';

  if (typeof resource === 'string') {
    url = resource;
  } else {
    method = resource.method || method;
    payload = resource.payload || payload;
    url = resource.url;
    contentType = resource.contentType || contentType;
  }
  actionPayload = actionPayload || {};
  return async function (dispatch, getState) {
    // TODO: prevent multiple simultaneous calls to same API endpoint?
    // TODO: automatically track an inProgress state for each API endpoint?
    let results = {};
    try {
      dispatchActions(dispatch, startAction, {url, ...actionPayload});
      var response = await fetch(url, {
        method,
        cache: 'no-store',
        headers: {
          'Content-Type': contentType,
          Accept: 'application/json',
          'X-Evite-Guest-Id': window.guest_id,
        },
        body: payload && contentType.indexOf('json') >= 0 ? JSON.stringify(payload) : payload,
      });

      if (!response.ok) {
        evite.error(`Failed to retry fetch ${url}`);
        if (response.status >= 500 && retries > 0) {
          return apiFetcher(resource, null, successAction, failureAction, retries - 1);
        }
        try {
          results = await response.json();
        } catch (e) {}
        throw response.status;
      }
      try {
        results = await response.json();
        // reportDataLayer(results && results.data_layer);  Todo: remove?
      } catch (e) {
        evite.error('Unable to parse JSON response - proceeding as if empty response', e);
      }
      const message = results.message || results;
      dispatchActions(dispatch, successAction, {
        results: message,
        ...actionPayload,
      });
    } catch (e) {
      evite.error(`Failed to fetch ${url}`, e);
      dispatchActions(dispatch, failureAction, {
        error: e,
        response,
        results,
        ...actionPayload,
      });
    }
  };
}
